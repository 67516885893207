.leaflet-sbs {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.leaflet-sbs-range {
  position: absolute;
  top: 15%;
  left: -9px;
  width: 100%;
  z-index: 999;
}
.leaflet-sbs-divider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -2px;
  width: 4px;
  background-color: #fff;
  pointer-events: none;
  z-index: 999;
}

.leaflet-sbs-divider::before,
.leaflet-sbs-divider::after {
  position: absolute;
  top: 15%;
  height: 32px;
  text-align: center;
  line-height: 32px;
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
}

.leaflet-sbs-divider::before {
  content: attr(data-from-year);
  left: 0;
  padding-right: 25px;
  padding-left: 12px;
  transform: translate3d(-100%, -50%, 0);
}

.leaflet-sbs-divider::after {
  content: attr(data-to-year);
  left: 0;
  padding-left: 25px;
  padding-right: 12px;
  transform: translate3d(0, -50%, 0);
}

.leaflet-sbs-range {
  -webkit-appearance: none;
  display: inline-block!important;
  vertical-align: middle;
  height: 0;
  padding: 0;
  margin: 0;
  border: 0;
  background: rgba(0, 0, 0, 0.25);
  min-width: 100px;
  cursor: pointer;
  pointer-events: none;
  z-index: 999;
}

.leaflet-sbs-divider-control {
  position: absolute;
  left: 50%;
  top: 15%;
  z-index: 1000;
  width: 40px;
  height: 40px;
  background-color: white;
  background-image: url('/range-icon.png');
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 40px 40px;
  border-radius: 20px;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  transform: translate3d(-50%, -50%, 0);
}

.leaflet-sbs-range::-ms-fill-upper {
  background: transparent;
}
.leaflet-sbs-range::-ms-fill-lower {
  background: rgba(255, 255, 255, 0.25);
}
/* Browser thingies */

.leaflet-sbs-range::-moz-range-track {
  opacity: 0;
}
.leaflet-sbs-range::-ms-track {
  opacity: 0;
}
.leaflet-sbs-range::-ms-tooltip {
  display: none;
}
/* For whatever reason, these need to be defined
* on their own so dont group them */

.leaflet-sbs-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  margin: 0;
  padding: 0;
  background: transparent;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: ew-resize;
  pointer-events: auto;
}
.leaflet-sbs-range::-ms-thumb {
  -webkit-appearance: none;
  margin: 0;
  padding: 0;
  background: transparent;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: ew-resize;
  pointer-events: auto;
}
.leaflet-sbs-range::-moz-range-thumb {
  -webkit-appearance: none;
  margin: 0;
  padding: 0;
  background: transparent;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: ew-resize;
  pointer-events: auto;
  border: none;
}
.leaflet-sbs-range:disabled::-moz-range-thumb {
  cursor: default;
}
.leaflet-sbs-range:disabled::-ms-thumb {
  cursor: default;
}
.leaflet-sbs-range:disabled::-webkit-slider-thumb {
  cursor: default;
}
.leaflet-sbs-range:disabled {
  cursor: default;
}
.leaflet-sbs-range:focus {
  outline: none!important;
}
.leaflet-sbs-range::-moz-focus-outer {
  border: 0;
}

@media screen and (max-width: 768px) {
  .leaflet-sbs-divider::before,
  .leaflet-sbs-divider::after {
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    font-weight: 500;
  }

  .leaflet-sbs-divider::before {
    padding-right: 28px;
  }

  .leaflet-sbs-divider::after {
    padding-left: 28px;
  }
}
