.wrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 3000;
  transition: all .5s ease;
  transform: translate3d(410px, -66px, 0);

  @media screen and (max-width: 960px) {
    transform: translate3d(390px, -66px, 0);
  }
}

.wrapperWithoutHeader {
  transform: translate3d(16px, -66px, 0);

  @media screen and (max-width: 768px) {
    transform: translate3d(10px, -66px, 0);
  }
}

.wrapperWithBeforeAndAfterMosaic {
  @media screen and (max-width: 768px) {
    bottom: 24px;
  }
}

.controlWrapper {
  display: flex;
  align-items: center;
  height: 32px;
  background-color: rgba(255, 255, 255, .5);
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  border-radius: 6px;
}

.searchButton {
  width: 32px;
  height: 32px;
  padding: 0;
  background-color: white;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 200ms ease;
  border-right: 1px solid #DADADA;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;

  &:hover {
    background-color: #F1F1F1;
  }

  & svg {
    font-size: 22px;
    fill: #555;
  }

  @media screen and (max-width: 1024px) {
    border-radius: 6px;
  }
}

.title {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 700;
  line-height: 30px;
}

.formWrapper {
  position: absolute;
  left: 44px;
  bottom: 42px;
  width: 320px;
  display: flex;
  align-items: center;
  padding: 26px 10px 12px 12px;
  background-color: white;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  border-radius: 6px;

  & h3 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    color: #16a085;
  }

  & label {
    background-color: #F7F7F7;
  }

  @media screen and (max-width: 768px) {
    width: 280px;
    padding: 16px 10px 12px 10px;
  }
}

.textInfo {
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 1.4;
}

.formControl {
  width: 120px;
  margin-right: 12px;

  & input {
    text-align: right;
  }
}

.searchIconButton {
  padding: 8px;
  margin-left: auto;

  & svg {
    font-size: 20px;
    color: #777;
  }
}

.infoIcon {
  margin-right: 8px;
  font-size: 18px;
  fill: #555;
  cursor: help;
}
