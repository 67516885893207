.wrapper {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 4000;
  display: flex;
  flex-direction: column;
  width: 360px;
  max-height: calc(100% - 70px);
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  transition: width .5s ease, height .5s ease, transform .5s ease, opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  transform: translateX(0);

  @media screen and (max-width: 620px) {
    width: calc(100% - 30px);
    //height: auto;
    transform: translate(16px, 30px);
    max-height: calc(100% - 156px);
  }
}

.wrapperWithHeader {
  //transform: translate(20px, 0px);
  //width: calc(100% - 478px);
}

.wrapperIsHidden {
  display: none;
  visibility: hidden;
}

.header {
  position: relative;
  padding: 16px;
}

.title {
  display: flex;
  align-items: center;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: .07em;
  color: #353f4d;
}

.closeButton {
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 3px;

  &:hover {
    & svg {
      fill: #8E8E8E;
    }
  }

  & svg {
    font-size: 26px;
    fill: #A2A2A2;
    transform: rotate(180deg);
    transition: fill 200ms ease-in-out, transform .5s ease;
  }
}

.closeButtonCollapse {
  & svg {
    transform: rotate(0deg);
  }
}

.collapseWrapper {
  overflow: auto;
}

.content {
  position: relative;
  overflow-y: auto;
  padding: 4px 16px 16px;
}

.section {
  margin-bottom: 32px;

  &:nth-child(2) {
    margin-bottom: 24px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}


.secondaryTitle {
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 15px;
  font-weight: 500;
  color: #555;

  & > small {
    display: block;
    margin-top: 4px;
    font-size: 11px;
    font-weight: 400;
    color: #616161;
  }

  & svg {
    position: relative;
    top: 3px;
  }
}

.secondaryTitleExtraInfo {
  display: block;
  margin-top: 4px;
  font-size: 13px;
  color: #717171;
}

.infoList {
  display: flex;
  padding-left: 0;
  padding-bottom: 8px;
  margin-top: 0;
  margin-bottom: 16px;
  border-bottom: 1px solid #DEDEDE;
}

.infoListItem {
  display: flex;
  flex-direction: column;
  margin-right: 42px;
}

.infoListItemValue {
  margin-bottom: 4px;
  font-size: 14px;

  & b {
    font-weight: 500;
  }

  & small {
    margin-left: 4px;
    font-size: 12px;
    font-weight: 400;
  }
}

.infoListItemLabel {
  font-size: 12px;
  font-weight: 400;
}

.progressWrapper {
  margin-bottom: 16px;
}

.actionWrapper {
  display: flex;
  justify-content: center;
  margin-top: -16px;
}

.actionButton {
  & svg {
    font-size: 20px;
    margin-right: 8px;
  }
}

.infoBox {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #d6d8db;
  border-radius: 4px;
  color: #383d41;
  background-color: #e2e3e5;

  & p {
    margin: 0;
    font-size: 12px;
    line-height: 1.4;
  }
}
