@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex: 1;
}

.logo {
  width: 334px;
  height: 67px;
  display: block;
  margin: 80px auto 60px auto;
}

.box {
  display: block;
  margin: 0 auto;
  width: 623px;
  height: 667px;
  margin-bottom: 112px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contentWrapper {
  padding: 40px 108px 20px;
}

.title {
  font-family: 'Open Sans', sans-serif;
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 34px;
  font-weight: 600;
  line-height: 41.99px;
  color: #404040;
  text-align: center;
  letter-spacing: 0.25px;
}

.subtitle {
  text-align: center;
  margin-bottom: 48px;
  color: #646464;
  font-size: 16px;
}

.formControl {
  margin-top: 12px;
  margin-bottom: 24px;
}

.actionsWrapper {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0 20px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  padding: 0 16px;
  background-color: #636466;

  @media screen and (max-width: 768px) {
    justify-content: center;
  }
}

.copyText {
  font-size: 12px;
  color: white;

  @media screen and (max-width: 768px) {
    font-size: 10px;
  }
}

.actionsWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 4px;
}

.accessAccountLegend {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 12px;
  margin-top: 42px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.38);
}

.helperLink {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size:14px;
  color: #16A085;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    color: #015f4c;
  }
}

.button {
  font-family: Open Sans;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 6px;
}

