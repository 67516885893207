.dialogWrapper {
  position: relative;
}

.closeButton {
  position: absolute;
  top: -10px;
  right: -10px;
}

.alertText {
  font-size: 12px;
  text-align: center;
}

.tempDialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px;

  & p {
    margin-top: 0;
  }

  & button {
    margin-top: 16px;
  }
}
