.content {
  margin-top: 24px;
  overflow-y: scroll;
}

.chartsWrapper {
  display: flex;
  margin-top: 0;

  & > div:first-child {
    width: 25%;
  }

  & > div:last-child {
    width: 75%;
  }

  & h3 {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;

    & > div:first-child,
    & > div:last-child {
      width: 100%;
      margin-bottom: 16px;
    }
  }
}

.dataTableWrapper {
  margin-top: 12px;
  margin-bottom: 16px;
}

.tableHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.tableTitle {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 700;

  & > span {
    margin-left: 4px;
    font-weight: 400;
    font-size: 12px;
  }
}

.tableWrapper {
  max-width: 100%;
  overflow-x: scroll;
}

.table {
  & th,
  & td {
    font-size: 12px;
  }

  & th {
    font-weight: 700;
    white-space: nowrap;
  }
}

.loadingWrapper {
  margin-top: 32px;
  text-align: center;
}

.csvDownloadLink {
  display: flex;
  align-items: center;
  padding: 4px 15px;
  font-size: 0.8125rem;
  text-transform: uppercase;
  color: #FFF;
  border-radius: 4px;
  transition: all 250ms ease-in-out;

  & svg {
    margin-right: 10px;
  }

  @media screen and (max-width: 480px) {
    display: none;
  }
}
