@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex: 1;
}

.logo {
  width: 334px;
  height: 67px;
  display: block;
  margin: 80px auto 60px auto;
}

.box {
  display: block;
  margin: 0 auto;
  width: 623px;
  height: 667px;
  margin-bottom: 112px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form {
  & .MuiInputBase-root {
    background: rgba(96, 171, 62, 0.03) !important;
  }
}

.contentWrapper {
  padding: 40px 108px 20px;
}

.title {
  font-family: 'Open Sans', sans-serif;
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 34px;
  font-weight: 600;
  line-height: 41.99px;
  color: #404040;
  text-align: center;
}

.subtitle {
  text-align: center;
  margin-bottom: 48px;
  color: #646464;
  font-size: 16px;
}

.formControl.emailField {
  margin-bottom: 24px;
}

.actionsWrapper {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0 36px;
}

.button {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  text-transform: uppercase;
  width: 80px;
  height: 33px;
  background: #16A085;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}

.createAcountLegend {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: '17.16px';
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.38);
}

.actionsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.helperLink {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size:14px;
  color: #16A085;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    color: #015f4c;
  }
}

