.contentWrapper {
  position: relative;
}

.sankeyWrapper {
  width: 100%;
  height: 320px;
  overflow-y: scroll;
}

.sankeyYears {
  margin-top: 0;
  text-align: center;
  font-size: 14px;
}

.autocompleteFormControl {
  margin-bottom: 24px;
}

.downloadButton {
  position: absolute;
  top: -4px;
  right: 6px;
  min-width: 46px;
  padding-left: 4px;
  padding-right: 4px;
  color: #5c5c5c;

  & svg {
    font-size: 20px;
  }
}
