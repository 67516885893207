.title {
  margin-top: 0;
  margin-bottom: 16px;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: .07em;
  color: #353f4d;
}

.classList {
  padding: 0;
  margin: 0;
}

.classListLevel2,
.classListLevel3,
.classListLevel4 {

  & .classListItemBase {
    border-bottom: none;
  }
}

.classListItemBase {
  border-bottom: 1px solid #DEDEDE;
}

.classListItemHeader {
  display: flex;
  align-items: center;
  padding: 6px 4px;
}

.classListItemCheckControl {
  padding: 4px;

  & svg {
    font-size: 18px;
  }
}

.classListItemTitle {
  flex: 1;
  margin-left: 4px;
  margin-right: 4px;
  font-size: 13px;
  color: #616161;
}

.classListItemContentControl {
  padding: 4px;

  & svg {
    font-size: 22px;
  }
}

.classListItemInfoControl {
  padding: 2px;
  margin-right: 9px;

  & svg {
    font-size: 16px;
  }
}

.classListLevel2 {
  margin-left: 28px;
  padding-bottom: 4px;

  & .classListItemHeader {
    padding: 0 4px 0 0;
  }

  & .classListItemCheckControl {
    & svg {
      font-size: 17px;
    }
  }

  & .classListItemTitle {
    font-size: 12px;
  }
}

.classListLevel3 {
  margin-left: 24px;

  & .classListItemHeader {
    padding: 0 4px 0 0;
  }

  & .classListItemCheckControl {
    & svg {
      font-size: 16px;
    }
  }

  & .classListItemTitle {
    font-size: 11px;
  }
}

.classListLevel4 {
  margin-left: 32px;

  & .classListItemHeader {
    padding: 0;
  }

  & .classListItemCheckControl {
    & svg {
      font-size: 14px;
    }
  }

  & .classListItemTitle {
    font-size: 10px;
  }
}

.checkboxLabel {
  font-size: 13px;
  color: #616161;
}
