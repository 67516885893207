.content {
  margin-top: 12px;
  overflow-y: scroll;
}

.chartsWrapper {
  display: flex;
  margin-top: 0;

  & > div:first-child {
    width: 25%;
  }

  & > div:last-child {
    width: 75%;
  }

  & h3 {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
  }
}

.dataTableWrapper {
  margin-top: 12px;
  margin-bottom: 16px;
}

.tableHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.tableTitle {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 700;
}

.tableWrapper {
  max-width: 100%;
  overflow-x: scroll;
}

.table {
  & th,
  & td {
    font-size: 12px;
  }

  & th {
    font-weight: 700;
    white-space: nowrap;
  }
}

.loadingWrapper {
  margin-top: 32px;
  text-align: center;
}
