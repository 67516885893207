.boxWrapper {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-bottom: 10px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
}

.button {
  width: 162px;
  padding: 12px 16px;
  border: none;
  font-size: 14px;
  color: #555;
  text-align: left;
  background-color: white;
  border-bottom: 1px solid #EBEAEF;
  transition: all 200ms ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: #f7f7f7;
  }

  &:last-child {
    border-bottom: none;
  }

  @media screen and (max-width: 960px) {
    padding: 8px 12px;
    font-size: 12px;
  }
}
