.pageWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}


@media print {
  .pageWrapper {
    height: auto !important;
  }
}

.contentWrapper {
  position: relative;
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;

  & iframe {
    margin-left: 60px;
  }
}
