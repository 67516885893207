.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  padding: 0 16px;
  background-color: #636466;

  @media screen and (max-width: 480px) {
    padding: 0 6px;
  }
}

.versionText {
  font-size: 12px;
  margin-right: 5px;
  color: white;

  @media screen and (max-width: 768px) {
    font-size: 10px;
  }

  @media screen and (max-width: 480px) {
    font-size: 9px;
  }
}

.copyText {
  font-size: 12px;
  color: white;

  @media screen and (max-width: 768px) {
    font-size: 10px;
  }

  @media screen and (max-width: 480px) {
    font-size: 9px;
  }
}

.alertText {
  font-size: 10px;
  color: white;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.ecostageImage {
  display: block;
  max-width: 86px;
  height: auto;

  @media screen and (max-width: 768px) {
    max-width: 72px;
  }

  @media screen and (max-width: 480px) {
    max-width: 60px;
  }
}

@media print {
  .wrapper {
    display: none;
  }
}
