.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 0 16px;
  background-color: #F7F7F7;
}

.title {
  margin: 0;
  color: #555;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

.wrapper {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 320px;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  color: #444;
  background-color: white;
  transition: all .5s ease;
  transform: translateX(100%);
  box-shadow: 0 2px 4px rgba(195, 195, 195, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.02);

  @media screen and (max-width: 768px) {
    z-index: 4000;
    width: 100%;
    padding-top: 50px;
    overflow-y: scroll;
  }
}

.isVisible {
  transform: translateX(0%);
}

.toggleButton {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  padding: 0;
  border: none;
  background-color: #555;
  transform: translateX(-100%);
  cursor: pointer;
  transition: all 200ms ease-in-out;

  &:hover {
    background-color: #616161;
  }

  & svg {
    font-size: 32px;
    color: #F7F7F7;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 30px;
}

.myMapsContentWrapper {
  padding: 20px 16px;
}

.translation {
  font-size: 14px;
  margin-right: 16px;

  & > a {
    display: inline-block;
    margin-left: 8px;
    color: #444;
    transition: color 200ms ease-in-out;

    &:hover {
      color: #16a085;
    }
  }
}

.subtitle {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 16px;
  margin-top: 0;
  margin-bottom: 16px;
  background-color: #F7F7F7;
  font-size: 16px;
}

.content {
  flex: 1;
  padding-bottom: 16px;
  overflow-y: scroll;
}

.infoText {
  margin-top: 0;
  margin-bottom: 42px;
  font-size: 14px;
  line-height: 1.4;
  text-align: center;

  & a {
    cursor: pointer;
    color: #16a085;
  }
}

.navList {
  padding: 0;
  margin: 0 16px;

  & > li {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;

    & > span {
      display: block;
      color: #444;
      font-size: 14px;
      cursor: default;
      text-transform: uppercase;
    }

    & > ul {
      padding-left: 16px;
      margin-top: 8px;

      & > li {
        display: block;
        margin-top: 8px;
        margin-bottom: 8px;

        & > a {
          display: block;
          color: #444;
          font-size: 12px;
          text-transform: uppercase;
          transition: color 200ms ease-in-out;

          &:hover {
            color: #16a085;
          }
        }
      }
    }
  }
}

.userInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  padding: 0 16px;
  background-color: #F7F7F7;
  border-top: 1px solid #DEDEDE;

  & > span:first-child {
    display: inline-flex;
    align-items: center;

    & svg {
      margin-right: 8px;
      font-size: 20px;
    }

    & span {
      font-size: 14px;
    }
  }

  & > button {
    padding: 2px;

    & svg {
      font-size: 20px;
    }
  }
}

.hideMenuButton {
  padding: 0;

  & svg {
    font-size: 24px;
    color: #717171;
  }
}
