.wrapper {
  width: 100%;
}

.bar {
  position: relative;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.backgroundBar,
.mainBar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  border-radius: 10px;
  background-color: #DEDEDE;
}

.mainBar {
  width: 0%;
  background-color: #e74c3c;
}

.info {
  display: flex;
  justify-content: space-between;
}

.infoLabel {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
}

.infoValue {
  font-size: 11px;

  & b {
    display: inline-flex;
    font-size: 12px;
    margin-right: 4px;
    font-weight: 500;
  }

  & small {
    font-size: 10px;
  }
}
