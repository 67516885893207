.wrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2000;
  transition: all .5s ease;
  transform: translate3d(442px, -66px, 0);

  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.wrapperWithoutHeader {
  transform: translate3d(48px, -66px, 0);
}

.controlWrapper {
  display: flex;
  align-items: center;
  height: 32px;
  background-color: rgba(255, 255, 255, .5);
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.mouseCoordinatesInfo {
  width: 94px;
  text-align: center;
  font-size: 12px;
  color: #333;
}
