.title {
  width: 100%;
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  color: #555;
}

.boxWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 180px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #F7F7F7;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  border-radius: 6px;

  & h3 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    color: #16a085;
  }

  & label {
    background-color: #F7F7F7;
  }

  @media screen and (max-width: 768px) {
    left: 42px;
  }
}

.textInfo {
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 1.4;
}

.formControl {
  width: 120px;
  margin-right: 12px;

  & input {
    text-align: right;
  }
}

.searchIconButton {
  padding: 8px;
  margin-left: auto;

  & svg {
    font-size: 20px;
    color: #777;
  }
}

.socialList {
  padding: 0;
  margin: 0;
  margin-top: 0;
  margin-bottom: 12px;
  list-style: none;
}

.socialItem {
  display: inline-block;
  margin-right: 16px;

  &:last-child {
    margin-right: 0;
  }

  & > div {
    cursor: pointer;
    outline: none;
  }
}
