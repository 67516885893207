.wrapper {
  position: absolute;
  bottom: 24px;
  left: 0;
  z-index: 4000;
  display: flex;
  align-items: center;
  width: calc(100% - 32px);
  padding-left: 8px;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0px 2px 7px rgba(125, 125, 125, 0.24);
  transition: all .5s ease;
  transform: translateX(16px);
  background-color: white;
  border-radius: 6px;

  @media screen and (max-width: 768px) {
    width: calc(100% - 20px);
    left: 10px;
    z-index: 3000;
    transform: translateX(0);
  }
}

.wrapperWithHeader {
  width: calc(100% - 426px);
  transform: translateX(410px);

  @media screen and (max-width: 960px) {
    width: calc(100% - 406px);
    transform: translateX(390px);
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.sliderWrapper {
  position: relative;
  z-index: 2000;
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  padding: 0 16px;
}

.timelineMark {
  width: 4px;
  height: 4px;
  margin-top: -1px;
  border-radius: 4px;

  @media screen and (max-width: 768px) {
    width: 2px;
    height: 2px;
    margin-top: 0;
    border-radius: 2px;
  }
}

.headerInfo {
  position: absolute;
  z-index: 1000;
  left: 50%;
  top: -31px;
  display: flex;
  align-items: center;
  height: 32px;
  padding-left: 16px;
  padding-right: 8px;
  background-color: white;
  transform: translateX(-50%);
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);

  @media screen and (max-width: 768px) {
    left: 0;
    transform: translateX(0%);
  }
}

.headerInfoLabel {
  font-size: 14px;
  color: #777;
}

.headerInfoValue {
  margin-left: 6px;
  margin-right: 8px;
  font-size: 14px;
  font-weight: 500;
}

.headerInfoToggleButton {
  padding: 4px;

  & svg {
    font-size: 20px;
  }
}

.timelineInfo {
  display: flex;
  align-items: center;
  flex-direction: row;

  & span:last-child {
    margin-left: 8px;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
  }
}

.valueText {
  display: flex;
  align-items: center;
  margin-left: 16px;
  margin-right: 16px;
  width: 222px;
}
