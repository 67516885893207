.dialogWrapper {
  padding: 16px;
}

.header {
  position: relative;
}

.title {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: .07em;
  color: #353f4d;
}

.closeButton {
  position: absolute;
  top: -6px;
  right: -6px;
  padding: 6px;

  & svg {
    font-size: 18px;
  }
}

.content {
  margin-top: 24px;
}

.contentDescription {
  font-size: 14px;
  line-height: 1.4;

  & a {
    color: #16a085;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}
