.formWrapper {
  margin: 0;
}

.textInfo {
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 1.4;
}

.formControl {
  margin-bottom: 32px;
}

.actionsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -6px;
}

.helperLink {
  font-size: 14px;
  color: #333;
  transition: all 200ms ease-in-out;

  &:hover {
    cursor: pointer;
    color: #16a085;
  }
}
