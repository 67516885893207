.list {
  padding: 0;
  margin-top: 6px;
  margin-bottom: 0;
}

.listItem {
  display: flex;
  align-items: center;
  padding: 4px 4px 4px 8px;
  margin-bottom: 4px;
  background-color: #F7F7F7;
  border: 1px solid #DEDEDE;
  border-radius: 4px;
}

.listItemText {
  flex: 1;
  font-size: 12px;
}

.listItemRemoveButton {
  padding: 2px;
  margin-left: 4px;

  & svg {
    font-size: 18px;
  }
}
