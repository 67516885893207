.title {
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 42px;
  font-size: 14px;
  font-weight: 500;
  color: #545451;
}

.formWrapper {
  margin: 0;
}

.textInfo {
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 1.4;
}

.formControl {
  margin-bottom: 32px;
}

.actionsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -6px;
}

.checkboxRoot {
  margin-top: -24px;
  margin-bottom: 24px;
}

.checkboxLabel {
  font-size: 14px;
}
