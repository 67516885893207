@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

.wrapper {
  display: flex;
  justify-content: center;
  padding: 0 66px 23px;
}

.helperLink {
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-decoration: underline;
  color: #616161;

  &:hover {
    color: #413f3f;
  }
}