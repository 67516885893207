.wrapper {
  position: relative;
  margin-bottom: 32px;
}

.classListItemBase {
  border-bottom: 1px solid #DEDEDE;
}

.classListItemHeader {
  display: flex;
  align-items: center;
  padding: 2px 4px;
}

.classListItemCheckControl {
  padding: 4px;

  & svg {
    font-size: 14px;
  }
}

.classListItemTitle {
  flex: 1;
  margin-left: 4px;
  margin-right: 4px;
  color: #333;
}

.classListItemContentControl {
  padding: 4px;

  & svg {
    font-size: 22px;
  }
}

.classListItemInfoControl {
  padding: 2px;
  margin-right: 5px;

  & svg {
    font-size: 16px;
  }
}

.classNode {
  padding: 0;
  margin: 0;
}

.classChildNode {
  margin-left: 28px;
  padding-bottom: 4px;

  & .classListItemHeader {
    padding: 0 4px 0 0;
  }

  & .classListItemInfoControl {
    margin-right: 5px;
  }

  & .classListItemBase {
    border-bottom: none;
  }
}

.title {
  margin-top: 0;
  margin-bottom: 16px;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: .07em;
  color: #353f4d;
}

.info {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 1.4;
  color: #616161;
}

.label {
  display: block;
  font-size: 12px;
  color: #616161;
}

.toggleButtons {
  display: flex;
  margin-top: 8px;
  margin-bottom: 14px;

  & > button {
    height: 32px;
    padding: 0;
    font-size: 14px;
    color: #555;
    background-color: white;
    border: 1px solid #DEDEDE;
    cursor: pointer;

    &:first-child {
      width: 40%;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      width: 60%;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-left-color: white;
    }
  }
}

.levelControls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.checkboxRoot {
  margin-right: 20px;
  margin-bottom: 6px;

  & > span:first-child {
    padding: 4px;
  }

  &:last-child {
    margin-right: 0;
  }
}

.checkboxLabel {
  font-size: 12px;
  color: #616161;
}

.actionButton {
  transition: all 200ms ease-in-out;
}

.descriptionText {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 12px;
}

.actionBoxControlButton {
  width: 24px;
  height: 24px;
  padding: 0;
  margin-left: 4px;

  & svg {
    font-size: 16px;
  }
}
