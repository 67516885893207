.classListItemBase {
  border-bottom: 1px solid #EBEAEF;
}

.classListItemHeader {
  display: flex;
  align-items: center;
  padding: 2px 4px;
}

.classListItemCheckControl {
  padding: 4px;

  & svg {
    font-size: 14px;
  }
}

.classListItemTitle {
  flex: 1;
  margin-left: 4px;
  margin-right: 4px;
  color: #616161;
}

.classListItemContentControl {
  padding: 4px;

  & svg {
    font-size: 22px;
  }
}

.classListItemInfoControl {
  padding: 2px;
  margin-right: 5px;

  & svg {
    font-size: 16px;
  }
}

.classNode {
  padding: 0;
  margin: 0;
}

.classChildNode {
  margin-left: 28px;
  padding-bottom: 4px;

  & .classListItemHeader {
    padding: 0 4px 0 0;
  }

  & .classListItemInfoControl {
    margin-right: 5px;
  }

  & .classListItemBase {
    border-bottom: none;
  }
}
