
.tableWrapper {
  position: relative;
  display: flex;
  background-color: #FAFAFA;
  overflow: scroll;
}

.categoriesTitle {
  display: block;
  height: 37px;
  min-width: 120px;
  padding-left: 12px;
  line-height: 37px;
  font-size: 12px;
  background-color: #E6E6E6;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.tableCategoriesList {
  padding-left: 0;
  margin: 0;

  & > li {
    height: 35px;
    padding-left: 12px;
    padding-right: 12px;
    line-height: 34px;
    white-space: nowrap;
    font-size: 12px;
    background-color: #F1F1F1;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }

  @media screen and (max-width: 768px) {
    & > li {
      padding-left: 8px;
      padding-right: 8px;
      font-size: 12px;
    }
  }
}

.table {
  & thead th {
    font-size: 12px;
    background-color: #F1F1F1;
  }

  & td {
    height: 34px;
    padding: 0 24px 0 16px;
    font-size: 12px;
    line-height: 34px;
    background-color: #FAFAFA;
  }
}

.categoryCell {
  white-space: nowrap;
}
