.navList {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  margin: 0;
  padding: 4px;
  background-color: #F5F5F5;
  border-radius: 6px;
}

.navListSmall {
  & .navListItem {
    font-size: 11px;
    font-weight: 500;
  }
}

.navListFullWidth {
  width: 100%;
}

.navListItem {
  display: flex;
  flex-basis: 50%;
  justify-content: center;
  padding: 8px 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: .07em;
  color: #8E8E8E;
  background-color: #F5F5F5;
  border-radius: 6px;
  transition: all 200ms ease-in-out;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
}

.disabledItem {
  pointer-events: none;
  opacity: .5;
}
