.mapList {
  padding-left: 0;
  padding-top: 24px;
  margin-top: 24px;
  border-top: 1px solid #EBEAEF;
}

.mapItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid #DEDEDE;

  &:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }
}

.mapItemImage {
  max-width: 80px;
  height: auto;
  margin-right: 16px;
}

.mapItemTextContent {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.mapItemTitle {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  color: #545451;
}

.mapItemDescription {
  margin-top: 4px;
  margin-bottom: 0;
  font-size: 12px;
  color: #555;
}

.mapItemActionsContent {
  margin-left: 12px;
}

.mapItemViewButton {
  padding: 3px;
  margin-left: 0;

  &:first-child {
    margin-right: 2px;
  }

  & svg {
    font-size: 18px;
  }
}

.mapItemViewButtonSecondary {
  & svg {
    font-size: 17px;
    fill: #999;
  }
}
