.title {
  margin-top: 12px;
  margin-bottom: 16px;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: .07em;
  color: #353f4d;
}

.text {
  font-size: 14px;
  line-height: 1.4;
}

// - ['#d73027',0.0,0,'']
// - ['#d73027',0.6,3,'']
// - ['#f46d43',0.6,5,'']
// - ['#fdae61',0.6,7,'']
// - ['#fee08b',0.6,9,'']
// - ['#ffffbf',0.6,11,'']
// - ['#d9ef8b',0.6,13,'']
// - ['#a6d96a',0.6,15,'']
// - ['#66bd63',0.6,17,'']
// - ['#1a9850',0.6,19,'']
// - ['#006837',0.6,23,'']

.legendGradient {
  position: relative;
  max-width: 100%;
  height: 32px;
  background: linear-gradient(
    to right,
    #d73027 0%,
    #d73027 13.04%,
    #f46d43 21.73%,
    #fdae61 30.43%,
    #fee08b 39.13%,
    #ffffbf 47.82%,
    #d9ef8b 56.52%,
    #a6d96a 65.21%,
    #66bd63 73.91%,
    #1a9850 82.60%,
    #006837 100%,
  );

  &::before,
  &::after {
    position: absolute;
    top: 34px;
    font-size: 14px;
  }

  &::before {
    content: '0';
    left: 0;
  }

  &::after {
    content: '23';
    right: 0;
  }
}
