@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: 'Lato', sans-serif;
}

.content {
  flex: 1;
}

.logo {
  width: 334px;
  height: 67px;
  display: block;
  margin: 80px auto 60px auto;
}

.contentBox {
  display: block;
  width: 820px;
  margin: 60px auto;
}

.formBox {
  display: block;
  width: 480px;
  padding: 20px;
  margin: 0 auto;
}

.contentWrapper {
  padding: 40px 0;
}

.contentTitleWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 420px;
  margin: 0 auto;

  & p {
    margin-top: 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.4;
    color: #808283;
  }
}

.contentTitle {
  font-size: 28px;
  font-weight: 400;

  & b {
    font-weight: 700;
  }
}

.imageBox {
  position: relative;

  & img:last-child {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.moduleContentWrapper {
  position: relative;
  padding: 0 40px;

  & .imageBox {
    position: absolute;
    top: 0;
    left: 100px;
  }
}

.sectionTitleGrid {
  margin-bottom: 48px;
  margin-top: 60px;
}

.sectionTitleBox {
  display: flex;
  align-items: center;
}

.sectionTitleBoxTitle {
  max-width: 180px;
  margin: 0;
  line-height: 1.4;
  font-size: 26px;
  font-weight: 900;
}

.sectionTitleBoxSubtitle {
  margin: 8px 0 0;
  font-size: 18px;
  font-weight: 700;
  color: #555;
}

.sectionTitleBoxInfo {
  margin-right: 40px;
  font-size: 16px;
  line-height: 1.4;
  color: #808283;
}

.triangleArrow {
  width: 0; 
  height: 0; 
  border-top: 60px solid transparent !important;
  border-bottom: 60px solid transparent !important;
  border-left: 60px solid green;
  margin-right: 32px;
}

.yearTitle {
  font-size: 18px;
  font-weight: 900;
  text-align: center;
}

.yearBlockImage {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.title {
  font-family: 'Open Sans', sans-serif;
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 34px;
  font-weight: 600;
  line-height: 41.99px;
  color: #404040;
  text-align: center;
  letter-spacing: 0.25px;
}

.subtitle {
  text-align: center;
  margin-bottom: 48px;
  color: #646464;
  font-size: 16px;
}

.formControl {
  margin-top: 12px;
  margin-bottom: 24px;
}

.actionsWrapper {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0 20px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  padding: 0 16px;
  background-color: #636466;

  @media screen and (max-width: 768px) {
    justify-content: center;
  }
}

.copyText {
  font-size: 12px;
  color: white;

  @media screen and (max-width: 768px) {
    font-size: 10px;
  }
}

.actionsWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 4px;
}

.button {
  font-family: Open Sans;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 6px;
}

.section {
  width: 100%;
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }
}

.secondaryTitle {
  margin-top: 32px;
  margin-bottom: 12px;
  font-size: 15px;
  font-weight: 700;
  color: #555;
  text-align: center;

  & > small {
    display: block;
    margin-top: 4px;
    font-size: 11px;
    font-weight: 400;
    color: #616161;
  }

  & svg {
    position: relative;
    top: 3px;
  }
}

.secondaryTitleExtraInfo {
  display: block;
  margin-top: 4px;
  font-size: 13px;
  color: #717171;
}

.progressWrapper {
  margin-bottom: 16px;
}

.actionWrapper {
  display: flex;
  justify-content: center;
  margin-top: -16px;
}

.actionButton {
  & svg {
    font-size: 20px;
    margin-right: 8px;
  }
}

.infoBox {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #d6d8db;
  border-radius: 4px;
  color: #383d41;
  background-color: #e2e3e5;

  & p {
    margin: 0;
    font-size: 12px;
    line-height: 1.4;
  }
}

.modulesBlock {
  width: 100%;
  padding: 60px 40px 44px;
  background-color: #DCDD78;
  margin: 32px 0;
}

.moduleBlock {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.moduleBlockIcon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  margin-right: 12px;
  background-color: white;
  border-radius: 4px;

  & svg {
    fill: #555;
  }
}

.moduleBlockTitle {
  font-size: 16px;
  font-weight: 700;
}
